// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../../../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-request-faqs-migration-index-js": () => import("./../../../src/pages/service-request/faqs/migration/index.js" /* webpackChunkName: "component---src-pages-service-request-faqs-migration-index-js" */),
  "component---src-pages-termsofuse-index-js": () => import("./../../../src/pages/termsofuse/index.js" /* webpackChunkName: "component---src-pages-termsofuse-index-js" */)
}

